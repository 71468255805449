import { axiosInstance } from "src/config/axiosInstance";

class QuotationsService {
    static async fetchQuotationsList(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/quotations/company/${companyId}`,
                );

                return response;
            } catch (error) {
                console.error("An error occurred:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        return [];
    }

    static async deleteQuotationById(quotationId) {
        try {
            const response = await axiosInstance.post(
                `/quotations/delete/${quotationId}`,
            );

            return response;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }

    static async fetchQuotationDetails(quotationId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/quotations/${quotationId}`,
                );

                return response;
            } catch (error) {
                console.error("Error fetching quotation details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async createQuotation(data, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    `/quotations/new`,
                    data,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    },
                );

                return response.data; // Assuming the project data is in the response's data property
            } catch (error) {
                console.error("Error creating quotation:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async updateQuotation(quotationId, data, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.put(
                    `/quotations/edit/${quotationId}`,
                    data,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    },
                );

                return response.data; // Assuming the updated quotation data is in the response's data property
            } catch (error) {
                console.error("Error updating quotation:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async createQuotationFromProject(projectId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/quotations/new/${projectId}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    },
                );

                return response.data; // Assuming the project data is in the response's data property
            } catch (error) {
                console.error("Error creating quotation:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }
    static async getQuotationByProjectId(projectId, maxRetries, retryDelay) {
      let retries = 0;
      while (retries < maxRetries) {
          try {
              const response = await axiosInstance.get(
                  `/quotations/project/${projectId}`,
                  {
                      headers: {
                          "Content-Type": "application/json",
                      },
                  },
              );

              return response.data; // Assuming the project data is in the response's data property
          } catch (error) {
              console.error("Error creating quotation:", error);
              retries++;
              await new Promise((resolve) => setTimeout(resolve, retryDelay));
          }
      }
  }
}

export default QuotationsService;
