// ----------------------------------------------------------------------

export function appBar(theme) {
    return {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                },
            },
        },
    };
}
