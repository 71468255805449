import { axiosInstance } from "src/config/axiosInstance";

class ProvidersService {
    static async fetchProvidersList(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/providers/company/${companyId}`,
                );

                return response;
            } catch (error) {
                console.error("An error occurred:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        return [];
    }

    static async deleteProviderById(providerId) {
        try {
            const response = await axiosInstance.post(
                `/providers/delete/${providerId}`,
            );

            return response;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }

    static async fetchProviderDetails(providerId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/providers/${providerId}`,
                );

                return response;
            } catch (error) {
                console.error("Error fetching provider details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async fetchProviderBookings(providerId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/providers/bookings/${providerId}`,
                );

                return response;
            } catch (error) {
                console.error("Error fetching provider details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }
    static async createProvider(data, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    `/providers/new`,
                    data,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    },
                );

                return response.data; // Assuming the project data is in the response's data property
            } catch (error) {
                console.error("Error creating provider:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        // Return null or an appropriate value if the retries are exhausted
        return null;
    }
}

export default ProvidersService;
