import { getAirpotsList } from "./getAirportList";
import { getNearestAirports } from "./getNearestAirports";
import { matchClassValues } from "./matchCabinClassValue";
import { removeDiacritics } from "./removeDiacritics";

export const processFlightsOptions = async (flightsOptions, projectData) => {
    const citiesDataGeo = projectData?.citiesData;    

    let searchSeparatedFlights=false;

    const initDate = flightsOptions?.departureDate.toISOString().split("T")[0];
    let slices = [];
    let passengers = [];
    const cities = Promise.all(
        projectData.cities.map(async (city) => {
            let response = await getAirpotsList(city);
    
            if (
                response.length === 0 ||
                (response?.[0]?.name &&
                    removeDiacritics(
                        response[0].name.toLowerCase()
                    )?.includes(removeDiacritics(city?.toLowerCase())) === false)
            ) {
                const cityData = citiesDataGeo.find((cityData) => cityData?.name === city);
                const body = {
                    radius: 30,
                    geoNameId: cityData?.geonameid,
                    queryString:city
                };
                searchSeparatedFlights=true;
                response = await getNearestAirports(body,3,500);
            }
    
            return {
                name: city,
                code: response?.[0]?.code || response?.airports?.[0]?.iata_code,
            };
        })
    );
    const citiesData = await cities;

    slices.push({
        origin: flightsOptions?.departureCity?.code,
        destination:
            flightsOptions?.arrivalAirport?.code || citiesData?.[0]?.code,
        departure_date: initDate,
        morningFilter: true,
        itineraryCity: flightsOptions?.arrivalAirport?.name?.length > 0 ? flightsOptions?.arrivalAirport?.name : citiesData?.[0]?.name,
        type:"arrival"
    });
    for (let i = 0; i < citiesData.length - 1; i++) {
        slices.push({
            origin: citiesData?.[i]?.code,
            destination: citiesData?.[i + 1]?.code,
            departure_date: projectData?.endDate?.[i],
            itineraryCity: citiesData?.[i]?.name,
            type:"departure"
        });
    }
    //para la vuelta si se ha seleccionado un aeropuerto de llegada/destino en el formulario de itinerario

    slices.push({
        origin:
            flightsOptions?.arrivalAirport?.code ||
            citiesData?.[citiesData.length - 1]?.code,
        destination: flightsOptions?.departureCity?.code,
        departure_date: projectData?.endDate?.[citiesData.length - 1],
        itineraryCity: citiesData?.[citiesData.length - 1]?.name,
        type:"departure"
    });

    for (let i = 0; i < flightsOptions.passangers.adults; i++) {
        passengers.push({
            type: "adult",
        });
    }
    for (let i = 0; i < flightsOptions.passangers.child; i++) {
        passengers.push({
            type: "child",
        });
    }
    for (let i = 0; i < flightsOptions.passangers.infants; i++) {
        passengers.push({
            type: "infant_without_seat",
        });
    }

    const max_connections = flightsOptions.directFlight ? 0 : 1;

    localStorage.setItem("flightsOptions", JSON.stringify(flightsOptions));
    return {
        slices,
        passengers,
        max_connections,
        cabin_class: matchClassValues(flightsOptions.class),
        currency: "EUR",
        searchSeparatedFlights
    };
};
