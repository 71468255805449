import { axiosInstance } from "src/config/axiosInstance";

class PromotionsService {
    static async fetchPromotions(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `${process.env.REACT_APP_HOST_API}/promotions/company/${companyId}`,
                );

                return response;
            } catch (error) {
                console.error("Error fetching tours summary:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }
    static async createPromotion(promotion) {
        try {
            const response = await axiosInstance.post(
                `${process.env.REACT_APP_HOST_API}/promotions/new`,
                promotion,
            );

            return response;
        } catch (error) {
            console.error("Error creating promotion:", error);
        }
    }
    static async generateEmailTemplateContent(data) {
        const response = await axiosInstance.post(
            `${process.env.REACT_APP_HOST_API}/promotions/templates/generateContent`,
            data,
        );

        return response;
    }

    static async createEmailTemplate(template) {
        try {
            const response = await axiosInstance.post(
                `${process.env.REACT_APP_HOST_API}/promotions/templates/new`,
                template,
            );

            return response;
        } catch (error) {
            console.error("Error creating promotion:", error);
        }
    }

    static async fetchEmailTemplates(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `${process.env.REACT_APP_HOST_API}/promotions/templates/company/${companyId}`,
                );

                return response;
            } catch (error) {
                console.error("Error fetching tours summary:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }
}

export default PromotionsService;
