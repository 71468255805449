//import { usePopover } from "src/components/custom-popover";

//import IconButton from "@mui/material/IconButton";
//import Iconify from "src/components/iconify";
//import MenuItem from "@mui/material/MenuItem";
//import { m } from "framer-motion";
//import { useCallback } from "react";
//import { useLocales } from "src/locales";
//import { varHover } from "src/components/animate";

// HDY: ATENCIÓN, SE COMENTA LA FUNCIONALIDAD DE MULT IDIOMA PARA LA APLICACIÓN DE DEMO PRODUCTIVA
export default function LanguagePopover() {
    //const locales = useLocales();

    //const popover = usePopover();

    /* const handleChangeLang = useCallback(
        (newLang) => {
            locales.onChangeLang(newLang);
            popover.onClose();
        },
        [locales, popover]
    ); */

    return (
        <>
            {/*  <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          ...(popover.open && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        <Iconify icon={locales.currentLang.icon} sx={{ borderRadius: 0.65, width: 28 }} />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 160 }}>
        {locales.allLangs.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === locales.currentLang.value}
            onClick={() => handleChangeLang(option.value)}
          >
            <Iconify icon={option.icon} sx={{ borderRadius: 0.65, width: 28 }} />

            {option.label}
          </MenuItem>
        ))}
      </CustomPopover> */}
        </>
    );
}
