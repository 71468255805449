// assets
//
import { _mock } from "./_mock";

// ----------------------------------------------------------------------

export const LEAD_STATUS_OPTIONS = [
    { value: "contacted", label: "Contacted" },
    { value: "new", label: "New" },
];

export const CUSTOMER_OPTIONS = [
    { value: "B2B", label: "B2B" },
    { value: "B2C", label: "B2C" },
    { value: "B2B2C", label: "B2B2C" },
];

export const LEAD_QUALIFICATION_OPTIONS = [
    {
        value: 1,
        label: "1 - Very Low: ",
        description:
            "Customer with very little interest and difficult to convert.",
    },
    {
        value: 2,
        label: "2 - Low: ",
        description: "Customer with limited interest and little commitment.",
    },
    {
        value: 3,
        label: "3 - Medium: ",
        description: "Customer with moderate interest",
    },
    {
        value: 4,
        label: "4 - High: ",
        description: "Customer with interest and some commitment",
    },
    {
        value: 5,
        label: "5 - Very High: ",
        description: "Customer with high interest and high willingness.",
    },
];
