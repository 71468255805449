import { axiosInstance } from "src/config/axiosInstance";

class CampaignsService {
    static async fetchCampaigns(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `${process.env.REACT_APP_HOST_API}/campaigns/company/${companyId}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching campaigns :", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }
    static async fetchContactForms(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `${process.env.REACT_APP_HOST_API}/campaigns/contact/company/${companyId}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching campaigns :", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }
}

export default CampaignsService;
