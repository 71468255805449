const hasSpecialCharsRegex = /[^a-zA-Z0-9]/g;

export const hasSpecialChars = (value) => {
    return hasSpecialCharsRegex.test(value.replace(/ /g, ""));
};

const hasSpecialCharsOrNumbersRegex = /[^a-zA-Z]/g;
export const hasSpecialCharsOrNumbers = (value) => {
    return hasSpecialCharsOrNumbersRegex.test(value.replace(/ /g, ""));
};
