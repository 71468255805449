import { _mock } from "./_mock";

// ----------------------------------------------------------------------

export const BOOKING_STATUS_OPTIONS = [
    { value: "pending", label: "Pending" },
    { value: "CONFIRMED", label: "Confirmed" },
    { value: "cancelled", label: "Cancelled" },
    { value: "refunded", label: "Refunded" },
];


export const BOOKING_GENDER_OPTIONS = [
    { label: "Man", value: "man" },
    { label: "Woman", value: "woman" },
    { label: "Other", value: "other" },
];

export const BOOKING_TYPE_OPTIONS = [
    { label: "Hotel", value: "hotel" },
    { label: "Activity", value: "activity" },
    { label: "Flight", value: "flight" },
    { label: "Car", value: "car" },
    { label: "Package", value: "package" },
    { label: "Transfer", value: "transfer" },
    { label: "Visa", value: "visa" },
    { label: "Insurance", value: "insurance" },
    { label: "Cruise", value: "cruise" },
    { label: "Train", value: "train" },
    { label: "Bus", value: "bus" },
    { label: "Event", value: "event" },
    { label: "Restaurant", value: "restaurant" },
    { label: "Spa", value: "spa" },
    { label: "Golf", value: "golf" },
    { label: "Tour", value: "tour" },
 
];
