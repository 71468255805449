/*
 * Important Note on Table ID for Printing
 * ---------------------------------------
 *
 * To utilize the printing functionality effectively, it is crucial that the table intended
 * for printing is assigned the specific ID 'table-to-print'. This ID is used by the printing
 * function to accurately locate the table within the document and prepare it for printing.
 *
 * Example:
 * <table id="table-to-print">...</table>
 *
 * DO NOT MODIFY THIS ID:
 * The ID 'table-to-print' is hardcoded into the printing functionality. Changing or using a different
 * ID will result in the printing function being unable to locate the table, thus preventing the
 * printing process from proceeding correctly.
 *
 * Always ensure that the table you wish to print carries this exact ID. If multiple tables need
 * to be printed, consider implementing additional logic within the printing function to handle
 * such scenarios, rather than altering the ID of the table.
 */

export const handlePrint = () => {
    const originalTable = document.getElementById("table-to-print");
    const cloneTable = originalTable.cloneNode(true);

    // Eliminar la columna 'Action'
    Array.from(cloneTable.querySelectorAll("th, td")).forEach((cell) => {
        if (cell.textContent.includes("Action")) {
            // Ajustado para verificar inclusión de texto
            const columnIndex = cell.cellIndex;
            Array.from(cloneTable.querySelectorAll("tr")).forEach((row) => {
                if (row.cells.length > columnIndex) {
                    // Verificar si la celda existe antes de eliminar
                    row.deleteCell(columnIndex);
                }
            });
        }
    });

    // Eliminar las flechas de ordenamiento
    Array.from(cloneTable.querySelectorAll(".MuiTableSortLabel-icon")).forEach(
        (icon) => icon.remove(),
    );

    Array.from(cloneTable.querySelectorAll("svg")).forEach((svg) =>
        svg.remove(),
    );

    Array.from(cloneTable.querySelectorAll(".css-pi4kbt")).forEach((div) => {
        if (div.textContent.includes("sorted ascending")) {
            div.remove();
        }
    });
    // Añadir aquí la eliminación de todas las imágenes
    Array.from(cloneTable.querySelectorAll("img")).forEach((img) =>
        img.remove(),
    );

    // Preparar y abrir la ventana de impresión
    const win = window.open("", "", "height=700,width=700");
    win.document.write(`
            <html>
            <head>
            <title>Print</title>
            <style>
                body { font-family: Arial, sans-serif; }
                table { width: 100%; border-collapse: collapse; }
                th, td { border: 1px solid #ddd; padding: 8px; }
                th { background-color: #f2f2f2; }
                /* Añade aquí más estilos según sea necesario */
            </style>
            </head>
            <body>
        `);
    win.document.body.appendChild(cloneTable); // Usar appendChild para el contenido clonado
    win.document.write("</body></html>");
    win.document.close();

    // Llamar a print() directamente sin cerrar la ventana automáticamente
    win.onload = function () {
        win.focus(); // Necesario para algunos navegadores
        win.print();
        // win.close(); Removido para permitir que el usuario cierre manualmente
    };
};
