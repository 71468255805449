import { axiosInstance } from "src/config/axiosInstance";

class MailsService {
    static async deleteMails(emailIds) {
        try {
            const response = await axiosInstance.post(`/emails/delete`, {
                emailIds,
            });

            return response;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }
    static async archiveMails(emailIds) {
        try {
            const response = await axiosInstance.post(
                `/emails/sentToArchive `,
                {
                    emailIds,
                },
            );

            return response;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }
}

export default MailsService;
