import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import PropTypes from "prop-types";
import { AuthContext } from "./auth-context";
import { axiosInstance } from "src/config/axiosInstance";
import { endpoints } from "src/utils/axios";
import { storageKeys } from "src/utils";
import { useLocales } from "src/locales";
import { initialState } from "./auth-reducer-state";
import { getWorkerPort } from "../../worker/worker-loader";

const ACTION_TYPES = {
    INITIAL: "INITIAL",
    LOGIN: "LOGIN",
    REGISTER: "REGISTER",
    LOGOUT: "LOGOUT",
    NO_SESSION: "NO-SESSION",
};

const reducer = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.INITIAL:
            return {
                loading: false,
                user: action.payload.user,
                /*  role: action.payload.role, */ // Set the user's role during initialization
                paymentMethods: action.payload.paymentMethods,
                isValidSubscription: action.payload.isValidSubscription,
            };
        case ACTION_TYPES.LOGIN:
            return {
                ...state,
                user: action.payload.user,
                paymentMethods: action.payload.paymentMethods,
                isValidSubscription: action.payload.isValidSubscription,
            };
        case ACTION_TYPES.REGISTER:
            return {
                ...state,
                user: action.payload.user,
            };
        case ACTION_TYPES.LOGOUT:
        case ACTION_TYPES.NO_SESSION:
            return {
                ...state,
                loading: false,
                user: null,
                paymentMethods: [],
                isValidSubscription: false,
            };
        default:
            return state;
    }
};

export function AuthProvider({ children }) {
    const { onChangeLang } = useLocales();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [userInfo, setUserInfo] = useState(null);

    const getSessionFromWorker = useCallback(async () => {
        const port = getWorkerPort();
        if (!port) {
            console.error("No se pudo obtener el puerto del worker");
            return null;
        }

        return new Promise((resolve, reject) => {
            const handleMessage = (e) => {
                if (e.data && e.data.type === "SESSION_DATA") {
                    port.removeEventListener("message", handleMessage);
                    port.removeEventListener("error", handleError);
                    resolve(e.data.payload.sessionData);
                }
            };

            const handleError = (error) => {
                port.removeEventListener("message", handleMessage);
                port.removeEventListener("error", handleError);
                reject(error);
            };

            port.addEventListener("message", handleMessage);
            port.addEventListener("error", handleError);

            try {
                port.postMessage({ type: "GET_SESSION" });
            } catch (error) {
                port.removeEventListener("message", handleMessage);
                port.removeEventListener("error", handleError);
                reject(error);
            }
        });
    }, []);

    const setSessionInWorker = useCallback(async (sessionData) => {
        const port = getWorkerPort();
        if (!port) {
            console.error("No se pudo obtener el puerto del worker");
            return;
        }

        try {
            port.postMessage({ type: "SET_SESSION", payload: sessionData });
        } catch (error) {
            console.error("Error al establecer la sesión en el worker:", error);
        }
    }, []);

    const clearSessionInWorker = useCallback(async () => {
        const port = getWorkerPort();
        if (!port) {
            console.error("No se pudo obtener el puerto del worker");
            return;
        }

        try {
            port.postMessage({ type: "CLEAR_SESSION" });
        } catch (error) {
            console.error("Error al limpiar la sesión en el worker:", error);
        }
    }, []);

    const setSessionOnLogin = useCallback(
        ({ userSession, setLang = true }) => {
            const { company } = userSession || {};
            console.log("USER SESSION", userSession);
            setSessionInWorker({
                token: userSession.token,
                user: userSession,
                superAdminToken: userSession.superAdminToken,
            });
            sessionStorage.setItem(
                storageKeys.USER,
                JSON.stringify({
                    ...userSession,
                    token: null,
                    superAdminToken: null,
                }),
            );
            sessionStorage.setItem(
                storageKeys.USER_NAME,
                userSession?.userName,
            );
            sessionStorage.setItem(storageKeys.TOKEN, userSession.token);
            if (userSession.superAdminToken) {
                sessionStorage.setItem(
                    storageKeys.SUPERADMIN_TOKEN,
                    userSession.superAdminToken,
                );
            }
            if (userSession.country) {
                sessionStorage.setItem(storageKeys.USER_COUNTRY, userSession.country);
            }
            sessionStorage.setItem(storageKeys.USER_NAME, userSession?.name);
            sessionStorage.setItem(storageKeys.USER_ID, userSession?.userId);
            sessionStorage.setItem(
                storageKeys.USER_CITY,
                userSession?.userCity,
            );
            sessionStorage.setItem(storageKeys.USER_LAT, userSession?.userLat);
            sessionStorage.setItem(
                storageKeys.USER_LONG,
                userSession?.userLong,
            );
            sessionStorage.setItem(
                storageKeys.USER_ROLE,
                userSession?.userRole,
            );
            sessionStorage.setItem(storageKeys.COMPANY_ID, company?.companyId);
            sessionStorage.setItem(storageKeys.CURRENCY, userSession?.currency);
            sessionStorage.setItem(storageKeys.LOGO, company?.avatarUrl);
            sessionStorage.setItem(
                storageKeys.COMPANY_NAME,
                company?.companyName,
            );
            sessionStorage.setItem(storageKeys.COMPANY_VAT, company?.vat);
            sessionStorage.setItem(storageKeys.BANK_NAME, company?.bankName);
            sessionStorage.setItem(
                storageKeys.BANK_ACCOUNT,
                company?.bankAccount,
            );
            sessionStorage.setItem(storageKeys.SWIFT, company?.swift);
            sessionStorage.setItem(storageKeys.EMAIL, company?.email);

            let customerLanguage = userSession?.language || "en";
            switch (customerLanguage) {
                case "Spanish":
                    customerLanguage = "es";
                    break;
                case "French":
                    customerLanguage = "fr";
                    break;
                case "English":
                    customerLanguage = "en";
                    break;
            }
            sessionStorage.setItem(storageKeys.LANGUAGE, customerLanguage);
            if (setLang) {
                onChangeLang(customerLanguage);
            }
            sessionStorage.setItem(
                storageKeys.BANK_ACCOUNT_OWNER,
                company?.bankAccountOwner,
            );
            sessionStorage.setItem(
                storageKeys.COMPANY_PARENT_NAME,
                company?.companyParentName,
            );
            sessionStorage.setItem(
                storageKeys.ONBOARDING_STATUS,
                company?.onboardingStatus,
            );
            sessionStorage.setItem(
                storageKeys.APPLY_TAX,
                userSession?.applicableTax,
            );
            sessionStorage.setItem(
                storageKeys.SUBSCRIPTION_PLAN,
                userSession?.subscriptionPlan,
            );
            sessionStorage.setItem(
                storageKeys.PAYMENT_METHODS,
                userSession?.paymentMethods,
            );
            sessionStorage.setItem(
                storageKeys.HOTELS_FEE,
                userSession?.feesHotels,
            );
            sessionStorage.setItem(
                storageKeys.ACTIVITIES_FEE,
                userSession?.fees.activitiesFee,
            );
            sessionStorage.setItem(
                storageKeys.TRANSFERS_FEE,
                userSession?.fees.transfersFee,
            );
            sessionStorage.setItem(storageKeys.CARS_FEE, userSession?.feesCars);
            sessionStorage.setItem(
                storageKeys.FLIGTHS_DOMESTIC_FEE,
                userSession?.fees.flightsDomesticFee,
            );
            sessionStorage.setItem(
                storageKeys.FLIGTHS_INTERNATIONAL_FEE,
                userSession?.fees.flightsInternationalFee,
            );
            sessionStorage.setItem(
                storageKeys.CRUISES_FEE,
                userSession?.fees.CruisesFee,
            );
            sessionStorage.setItem(
                storageKeys.PACKAGE_FEE,
                userSession?.fees.packagesFee,
            );
        },
        [onChangeLang, setSessionInWorker],
    );

    const initialize = useCallback(async () => {
        try {
            let sessionData = await getSessionFromWorker();
            if (!sessionData || !sessionData.token) {
                const token = sessionStorage.getItem(storageKeys.TOKEN);
                if (token) {
                    sessionData = { token };
                    await setSessionInWorker(sessionData);
                }
            }

            if (sessionData && sessionData.token) {
                sessionStorage.setItem(storageKeys.TOKEN, sessionData.token);
                const response = await axiosInstance.get(endpoints.auth.me); 

                if (!response.data.userSession) {
                    throw new Error("Session Expired");
                }
                const { paymentMethods, isValidSubscription, userSession } =
                    response.data;

                console.log(userSession);

                setSessionOnLogin({ userSession, setLang: false });

                dispatch({
                    type: ACTION_TYPES.INITIAL,
                    payload: {
                        user: userSession,
                        paymentMethods,
                        isValidSubscription,
                    },
                });
                return;
            }
        } catch (error) {
            console.error("Error en la inicialización:", error);
            await clearSessionInWorker();
            sessionStorage.clear();
        }
        dispatch({
            type: ACTION_TYPES.NO_SESSION,
        });
    }, [getSessionFromWorker, setSessionInWorker, clearSessionInWorker]);

    useEffect(() => {
        initialize();
    }, [initialize]);

    const login = useCallback(
        async (email, password, forceLogin = false) => {
            try {
                const response = await axiosInstance.post(
                    endpoints.auth.login,
                    {
                        email,
                        password,
                    },
                    { params: forceLogin ? { forceLogin: true } : {} },
                );
                const { userSession, isValidSubscription, paymentMethods } =
                    response.data;

                setSessionOnLogin({ userSession });

                dispatch({
                    type: ACTION_TYPES.LOGIN,
                    payload: {
                        user: userSession,
                        isValidSubscription,
                        paymentMethods,
                    },
                });
                return { status: "SUCCESS" };
            } catch (error) {
                if (error?.loginDate) {
                    return {
                        status: "ACTIVE_SESSION",
                        responseData: {
                            loginDate: error.loginDate,
                            device: error.device,
                        },
                    };
                }
                console.error("Error en login:", error);
                return { status: "ERROR" };
            }
        },
        [setSessionOnLogin],
    );

    const adminLogin = useCallback(
        async (userId) => {
            try {
                const response = await axiosInstance.post(
                    endpoints.users.adminLogin(userId),
                );
                const { userSession, isValidSubscription, paymentMethods } =
                    response.data;
                setSessionOnLogin({ userSession });

                dispatch({
                    type: ACTION_TYPES.LOGIN,
                    payload: {
                        user: userSession,
                        isValidSubscription,
                        paymentMethods,
                    },
                });
                return true;
            } catch (error) {
                console.error("Error en adminLogin:", error);
                return false;
            }
        },
        [setSessionOnLogin],
    );

    const register = useCallback(
        async (email, password, firstName, lastName) => {
            try {
                const data = {
                    email,
                    password,
                    firstName,
                    lastName,
                };

                const response = await axiosInstance.post(
                    endpoints.auth.register,
                    data,
                );

                const { accessToken, user } = response.data;

                await setSessionInWorker({ token: accessToken, user });

                dispatch({
                    type: ACTION_TYPES.REGISTER,
                    payload: {
                        user,
                    },
                });
            } catch (error) {
                console.error("Error en registro:", error);
            }
        },
        [setSessionInWorker],
    );

    const logout = useCallback(async () => {
        try {
            await axiosInstance.post(endpoints.auth.logout);
        } catch (err) {
            console.error("Error en logout:", err);
        } finally {
            await clearSessionInWorker();
            sessionStorage.clear();
            dispatch({
                type: ACTION_TYPES.LOGOUT,
            });
        }
    }, [clearSessionInWorker]);

    const status = state.loading
        ? "loading"
        : state.user
          ? "authenticated"
          : "unauthenticated";

    const memoizedValue = useMemo(
        () => ({
            user: state.user,
            paymentMethods: state.paymentMethods,
            isValidSubscription: state.isValidSubscription,
            method: "jwt",
            loading: status === "loading",
            authenticated: status === "authenticated",
            unauthenticated: status === "unauthenticated",
            //
            login,
            adminLogin,
            register,
            logout,
            paymentMethod,
            setPaymentMethod,
            userInfo,
            setUserInfo,
        }),
        [
            login,
            logout,
            register,
            state.user,
            state.paymentMethods,
            state.isValidSubscription,
            status,
            paymentMethod,
            setPaymentMethod,
            userInfo,
            setUserInfo,
        ],
    );

    return (
        <AuthContext.Provider value={memoizedValue}>
            {children}
        </AuthContext.Provider>
    );
}

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
