import React, { createContext, Context } from "react";
import { initialState } from "./auth-reducer-state";
// ----------------------------------------------------------------------

/**
 * @typedef {Object} AuthContextProps
 * @property {typeof initialState.user} user
 * @property {string[]} paymentMethods
 * @property {boolean} isValidSubscription
 * @property {"jwt"} method
 * @property {boolean} loading
 * @property {boolean} authenticated
 * @property {boolean} unauthenticated
 * @property {(email: string, password: string, forceLogin?: boolean) => Promise<{status: "SUCCESS" | "ACTIVE_SESSION" | "ERROR", responseData: any}>} login,
 * @property {(userId: string) => Promise<boolean>} adminLogin,
 * @property {(email: string, password: string, firstName: string, lastName: string) => Promise<void>} register,
 * @property {() => Promise<void>} logout,
 * @property {any | null} paymentMethod,
 * @property {React.Dispatch<any>} setPaymentMethod,
 * @property {any | null} userInfo,
 * @property {React.Dispatch<any>} setUserInfo,
 */
/**
 * @type {Context<AuthContextProps>}
 */
export const AuthContext = createContext({});
