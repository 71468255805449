import { axiosInstance } from "src/config/axiosInstance";

class ActivitiesService {
    static async postNewActivity(data) {
        try {
            const response = await axiosInstance.post(`/activities/new`, data);
            return response;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }
    ///activities/edit/:activityId updateActivity
    static async updateActivity(activityId, data) {
        try {
            const response = await axiosInstance.put(
                `/activities/edit/${activityId}`,
                data,
            );
            return response;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }

    static async bookActivity(bookingData, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    `/viator/book`,
                    bookingData,
                    {
                        headers: { "Content-Type": "application/json" },
                    },
                );
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while booking activity:",
                    error?.message,
                );
                retries++;
                bookingData = {
                    ...bookingData,
                    error: true,
                };
                if (retries >= maxRetries) {
                    return error;
                }
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async bookActivityIntent(bookingData, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    "/viator/bookIntent",
                    bookingData,
                );
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while booking activity:",
                    error?.message,
                );
                retries++;
                if (retries >= maxRetries) {
                    return error;
                }
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async cancellActivity(bookingData, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.delete(
                    `/viator/cancel-booking/${bookingData.bookingId}?lang=${bookingData.language}`,
                    {
                        headers: { "Content-Type": "application/json" },
                    },
                );
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while booking activity:",
                    error,
                );
                retries++;
                if (retries >= maxRetries) {
                    return error;
                }
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async ReHoldBooking(bookingId, lang, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.put(
                    `/viator/rebookhold/${bookingId}?lang=${lang}`,
                    {
                        headers: { "Content-Type": "application/json" },
                    },
                );
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while booking activity:",
                    error,
                );
                retries++;
                if (retries >= maxRetries) {
                    return error;
                }
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async FetchActivities(body, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    `/viator/searchCityProducts`,
                    body,
                );
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while fetching activities:",
                    error,
                );
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async FetchAttractions(body, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    `/viator/attractions`,
                    body,
                );
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while fetching attractions:",
                    error,
                );
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async getProductDetails(data, maxRetries, retryDelay) {
        let retries = 0;
        const { productCode, currentLang } = data;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/productDetails/${productCode}/${currentLang}`,
                );
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while fetching attractions:",
                    error,
                );
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async getSchedulesProduct(productCode, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/viator/schedules/${productCode}`,
                );
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while fetching attractions:",
                    error,
                );
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async getReviews(body) {
        try {
            const { data } = await axiosInstance.post(`/viator/reviews`, body);
            return data.data;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }

    static async DeleteActivityImage(body, activityDetailsId) {
        try {
            const response = await axiosInstance.put(
                `/activities/deleteImage/${activityDetailsId}`,
                body,
            );
            return response.data;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }

    static async getLocationsBulk(body, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    `/viator/locations`,
                    body,
                );
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while fetching locations:",
                    error,
                );
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async getQuestionDetails({ bookingQuestions, ageBands, lang }) {
        try {
            const { data: response } = await axiosInstance.post(
                `${process.env.REACT_APP_HOST_API}/viator/questions`,
                { bookingQuestions, ageBands, lang },
            );

            return response.questions;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }

    static async FetchLightActivities(body, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    `/viator/searchActivitiesLight`,
                    body,
                );
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while fetching activities:",
                    error,
                );
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async createManualActivityProposal(data) {
        try {
            const response = await axiosInstance.post(
                `/activities/manualProposal`,
                data,
            );
            return response.data;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }

    static async getManualActivityProposal(projectId) {
        try {
            const response = await axiosInstance.get(
                `/activities/manualProposal/${projectId}`,
            );
            return response.data;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }

}

export default ActivitiesService;
