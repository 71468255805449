const regex = /PT(?:(\d+)H)?(?:(\d+)M)?/;

export function parseDuration(durationString) {
    const match = durationString.match(regex);

    if (match) {
        const hours = match[1] ? parseInt(match[1], 10) : 0;
        const minutes = match[2] ? parseInt(match[2], 10) : 0;

        return `${hours} h ${minutes} min`;
    }

    return null;
}

export function parseTotalDuration(steps) {
    let hours = 0, minutes = 0;
    for (let i = 0; i < steps.length; i++) {
        const match = steps[i].match(regex);

        if (match) {
            hours += match[1] ? parseInt(match[1], 10) : 0;
            minutes += match[2] ? parseInt(match[2], 10) : 0;
        }
    }
    while (minutes > 60) {
      hours += 1;
      minutes -=60
    }
    return `${hours} h ${minutes} min`;
}
