import { axiosInstance } from "src/config/axiosInstance";

class AdminService {
    static async fetchAdminDataPanel(maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response =
                    await axiosInstance.get(`/admin/getAdminPanel`);
                return response;
            } catch (error) {
                console.error("An error occurred:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        return [];
    }

    static async fetchAllBookingsList(maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(`/bookings`);
                return response;
            } catch (error) {
                console.error("An error occurred:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        return [];
    }
}

export default AdminService;
