import { createContext, useContext } from "react";

// ----------------------------------------------------------------------

export const ExchangeRateContext = createContext({});

/**
 * @typedef {Object} ExchangeRateInterface
 * @property {string} currency
 * @property {number} rate
 */
/**
 * @typedef {Object} ExchangeRateProps
 * @property {ExchangeRateInterface} exchangeRate,
 * @property {(value: ExchangeRateInterface) => void} setExchangeRate
 * @property {(projectId: string, forceUpdate?: boolean) => Promise<void>} updateExchangeRate
 */
/**
 *
 * @returns {ExchangeRateProps}
 */
export const useExchangeRateContext = () => {
    const context = useContext(ExchangeRateContext);
    if (!context)
        throw new Error(
            "useExchangeRateContext must be use inside ExchangeRateProvider",
        );

    return context;
};
