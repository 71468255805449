import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";
import FileThumbnail from "src/components/file-thumbnail";
import Label from "src/components/label";
import { fToNow } from "src/utils/format-time";
import { useRouter } from "src/routes/hooks";
import { paths } from "src/routes/paths";
import { ProjectsService } from "src/api/tz";
import { useLocales } from "src/locales";

function DialogComponent({ open, onClose, notification }) {
    const router = useRouter();
    const { t } = useLocales();
    const handleOpenProject = async () => {
        console.log("Opening project:", notification.projectId);
        const response = await ProjectsService.fetchProjectDetailsByLast8Digits(
            notification.projectId,
            3,
            1000,
        );
        if (response && response.data) {
            router.push(paths.dashboard.project.profile(response.data._id));
            onClose();
        } else {
            console.error(
                "Full project ID not found for last 8 digits:",
                notification.projectId,
            );
        }
    };
    const friendAction = (
        <Stack spacing={1} direction="row" sx={{ mt: 1.5 }}>
            <Button size="small" variant="contained">
                {t("Accept")}
            </Button>
            <Button size="small" variant="outlined">
                {t("Decline")}
            </Button>
        </Stack>
    );

    const projectAction = (
        <Stack alignItems="flex-start">
            <Box
                sx={{
                    p: 1.5,
                    my: 1.5,
                    borderRadius: 1.5,
                    color: "text.secondary",
                    bgcolor: "background.neutral",
                }}
            >
                {reader(
                    `${notification.from} replied to ${notification.subject}`,
                )}
            </Box>

            <Button
                size="small"
                variant="contained"
                onClick={handleOpenProject}
            >
                {t("Open Project")}
            </Button>
        </Stack>
    );

    const fileAction = (
        <Stack
            spacing={1}
            direction="row"
            sx={{
                pl: 1,
                p: 1.5,
                mt: 1.5,
                borderRadius: 1.5,
                bgcolor: "background.neutral",
            }}
        >
            <FileThumbnail
                file="http://localhost:8080/httpsdesign-suriname-2015.mp3"
                sx={{ width: 40, height: 40 }}
            />

            <Stack
                spacing={1}
                direction={{ xs: "column", sm: "row" }}
                flexGrow={1}
                sx={{ minWidth: 0 }}
            >
                <ListItemText
                    disableTypography
                    primary={
                        <Typography
                            variant="subtitle2"
                            component="div"
                            sx={{ color: "text.secondary" }}
                            noWrap
                        >
                            design-suriname-2015.mp3
                        </Typography>
                    }
                    secondary={
                        <Stack
                            direction="row"
                            alignItems="center"
                            sx={{
                                typography: "caption",
                                color: "text.disabled",
                            }}
                            divider={
                                <Box
                                    sx={{
                                        mx: 0.5,
                                        width: 2,
                                        height: 2,
                                        borderRadius: "50%",
                                        bgcolor: "currentColor",
                                    }}
                                />
                            }
                        >
                            <span>2.3 GB</span>
                            <span>30 min ago</span>
                        </Stack>
                    }
                />

                <Button size="small" variant="outlined">
                    {t("Download")}
                </Button>
            </Stack>
        </Stack>
    );

    const tagsAction = (
        <Stack direction="row" spacing={0.75} flexWrap="wrap" sx={{ mt: 1.5 }}>
            <Label variant="outlined" color="info">
                {t("Design")}
            </Label>
            <Label variant="outlined" color="warning">
                {t("Dashboard")}
            </Label>
            <Label variant="outlined">{t("Design system")}</Label>
        </Stack>
    );

    const paymentAction = (
        <Stack direction="row" spacing={1} sx={{ mt: 1.5 }}>
            <Button size="small" variant="contained">
                {t("Pay")}
            </Button>
            <Button size="small" variant="outlined">
                {t("Decline")}
            </Button>
        </Stack>
    );
    const renderText = (
        <ListItemText
            disableTypography
            primary={reader(notification.title)}
            secondary={
                <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ typography: "caption", color: "text.disabled" }}
                    divider={
                        <Box
                            sx={{
                                width: 2,
                                height: 2,
                                bgcolor: "currentColor",
                                mx: 0.5,
                                borderRadius: "50%",
                            }}
                        />
                    }
                >
                    {fToNow(notification.createdAt)}
                    {notification.category}
                </Stack>
            }
        />
    );
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>{t("Notification Details")}</DialogTitle>
            <DialogContent>
                <Typography variant="h6">
                    {t("Details")}: {notification.title}
                </Typography>
                <Typography variant="body1">
                    {t("Subject")}: {notification.subject}
                </Typography>
                <Typography variant="body1">
                    {t("From")}: {notification.from}
                </Typography>
                <Typography variant="body1">
                    {t("Notification created on")}:{" "}
                    {new Date(notification.date).toLocaleString()}
                </Typography>
                <Typography variant="body1">
                    {t("Category")}: {notification.category}
                </Typography>

                {/* Add more details about the notification */}
            </DialogContent>
            <DialogActions>
                <Stack sx={{ flexGrow: 1 }}>
                    {renderText}
                    {notification.type === "friend" && friendAction}
                    {projectAction}
                    {notification.type === "file" && fileAction}
                    {notification.type === "tags" && tagsAction}
                    {notification.type === "payment" && paymentAction}
                </Stack>
                <Button onClick={onClose} color="primary">
                    {t("Close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

DialogComponent.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    notification: PropTypes.object.isRequired,
};

function reader(data) {
    return (
        <Box
            dangerouslySetInnerHTML={{ __html: data }}
            sx={{
                mb: 0.5,
                "& p": { typography: "body2", m: 0 },
                "& a": { color: "inherit", textDecoration: "none" },
                "& strong": { typography: "subtitle2" },
            }}
        />
    );
}

export default DialogComponent;
