import { axiosInstance } from "src/config/axiosInstance";

class TrackingsService {
    static async saveUserInteraction(data) {
        try {
            const response = await axiosInstance.post(
                `/trackings/user/new`,
                data,
            );

            return response;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }
    static async saveCustomerInteraction(data) {
        try {
            const response = await axiosInstance.post(
                `/trackings/customer/new`,
                data,
            );

            return response;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }
    static async getProjectInteractions(projectId) {
        try {
            const response = await axiosInstance.get(
                `/trackings/project/${projectId}`,
            );

            return response;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }
}

export default TrackingsService;
