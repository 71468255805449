// @mui
import { enUS, frFR, esES } from "@mui/material/locale";

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
    {
        label: "English",
        value: "en",
        systemValue: enUS,
        icon: "flagpack:gb-nir",
    },
    {
        label: "French",
        value: "fr",
        systemValue: frFR,
        icon: "flagpack:fr",
    },
    {
        label: "Español",
        value: "es",
        systemValue: esES,
        icon: "flagpack:es",
    },
];

export const defaultLang = allLangs[0]; // English

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
