import PropTypes from "prop-types";
import uniq from "lodash/uniq";
import { useEffect, useMemo, useCallback, useState } from "react";
// hooks
import { useLocalStorage } from "src/hooks/use-local-storage";
// routes
import { paths } from "src/routes/paths";
import { useRouter } from "src/routes/hooks";
// _mock
import { PRODUCT_CHECKOUT_STEPS } from "src/_mock/_product";
//
import { FlightContext } from "./checkout-context";

// ----------------------------------------------------------------------

const STORAGE_KEY = "checkout";

const initialState = {
    selectedFlight: [],
    passangers: [],
    activeStep: 0,
    activeStep: 0,
    items: [],
    subTotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null,
    totalItems: 0,
    price: { total_amount: 0, total_currency: "" },
};

export function FlightProvider({ children }) {
    const router = useRouter();

    const [values, setValues] = useLocalStorage(STORAGE_KEY, initialState);
    const [itinerary, setItinerary] = useState("");

    const setValue = useCallback(
        (name, value) => {
            setValues((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        },
        [setValues],
    );

    const onSelectFlight = useCallback((selectedFlight) => {
        setValue("selectedFlight", selectedFlight);
    }, []);

    const onSetPassangers = useCallback((passangers) => {
        setValue("passanger", passangers);
    }, []);

    const onSetPrice = useCallback(({ total_amount, total_currency }) => {
        setValue("price", { total_amount, total_currency });
    }, []);

    const onParseDuration = useCallback((durationString) => {
        const regex = /PT(?:(\d+)H)?(?:(\d+)M)?/;
        const match = durationString.match(regex);

        if (match) {
            const hours = match[1] ? parseInt(match[1], 10) : 0;
            const minutes = match[2] ? parseInt(match[2], 10) : 0;

            return `${hours} h ${minutes} min`;
        }

        return null;
    }, []);

    const onBackStep = useCallback(() => {
        setValue("activeStep", values.activeStep - 1);
    }, [setValue, values.activeStep]);

    const onNextStep = useCallback(() => {
        setValue("activeStep", values.activeStep + 1);
    }, [setValue, values.activeStep]);

    const onGotoStep = useCallback(
        (step) => {
            setValue("activeStep", step);
        },
        [setValue],
    );

    const onCreateBilling = useCallback(
        (address) => {
            setValue("billing", address);

            onNextStep();
        },
        [onNextStep, setValue],
    );

    const onApplyDiscount = useCallback(
        (discount) => {
            setValue("discount", discount);
        },
        [setValue],
    );

    const completed = values.activeStep === PRODUCT_CHECKOUT_STEPS.length;

    // Reset
    const onReset = useCallback(() => {
        if (completed) {
            setValues(initialState);
            router.replace(paths.product.root);
        }
    }, [completed, router, setValues]);

    const memoizedValue = useMemo(
        () => ({
            ...values,
            completed,
            setItinerary,
            itinerary,
            //
            onCreateBilling,
            onApplyDiscount,

            //
            onBackStep,
            onNextStep,
            onGotoStep,
            //
            onReset,
            onSelectFlight,
            onParseDuration,
            onSetPassangers,
            onSetPrice,
        }),
        [
            completed,
            onApplyDiscount,
            onBackStep,
            onCreateBilling,
            onGotoStep,
            onNextStep,
            onReset,
            values,
            setItinerary,
            itinerary,
            onSelectFlight,
            onParseDuration,
            onSetPassangers,
            onSetPrice,
        ],
    );

    return (
        <FlightContext.Provider value={memoizedValue}>
            {children}
        </FlightContext.Provider>
    );
}

FlightProvider.propTypes = {
    children: PropTypes.node,
};
