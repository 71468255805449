import PropTypes from "prop-types";
// @mui
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
// hooks
import { useResponsive } from "src/hooks/use-responsive";
// components
import Logo from "src/components/logo-saas";

// ----------------------------------------------------------------------

export default function AuthModernLayout({ children, image }) {
    const upMd = useResponsive("up", "md");
    //create aleatory number between 1-9
    const aleatoryNumber = Math.floor(Math.random() * 27) + 1;
    const renderContent = (
        <Stack
            sx={{
                width: 1,
                mx: "auto",
                maxWidth: 480,
                px: { xs: 2, md: 8 },
            }}
        >
            <Logo
                sx={{
                    mt: { xs: 2, md: 8 },
                    mb: { xs: 10, md: 8 },
                }}
            />

            <Card
                sx={{
                    py: { xs: 5, md: 0 },
                    px: { xs: 3, md: 0 },
                    boxShadow: { md: "none" },
                    overflow: { md: "unset" },
                    bgcolor: { md: "background.default" },
                }}
            >
                {children}
            </Card>
        </Stack>
    );

    const renderSection = (
        <Stack flexGrow={1} sx={{ position: "relative" }}>
            <Box
                component="img"
                alt="auth"
                src={image || `/assets/background/${aleatoryNumber}.jpg`}
                sx={{
                    top: 16,
                    left: 16,
                    objectFit: "cover",
                    position: "absolute",
                    width: "calc(100% - 32px)",
                    height: "calc(100% - 32px)",
                }}
            />
        </Stack>
    );

    return (
        <Stack
            component="main"
            direction="row"
            sx={{
                minHeight: "100vh",
                position: "relative",
                "&:before": {
                    width: 1,
                    height: 1,
                    zIndex: -1,
                    content: "''",
                    position: "absolute",
                    backgroundSize: "cover",
                    opacity: { xs: 0.24, md: 0 },
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    backgroundImage: "url(/assets/background/overlay_4.jpg)",
                },
            }}
        >
            {renderContent}

            {upMd && renderSection}
        </Stack>
    );
}

AuthModernLayout.propTypes = {
    children: PropTypes.node,
    image: PropTypes.string,
};
