import Papa from "papaparse";

export const parseCSV = (csvText) => {
    console.log('parseCSV', csvText);
    return new Promise((resolve, reject) => {
        Papa.parse(csvText, {
            header: true,
            complete: (results) => {
                resolve(results.data);
            },
            error: (error) => {
                reject(error);
            },
        });
    });
};
