import { axiosInstance } from "src/config/axiosInstance";

class InvoicesService {
    static async fetchInvoicesListByCompany(companyId) {
        try {
            const response = await axiosInstance.get(
                `/invoices/company/${companyId}`,
            );
            return response;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }
    static async getInvoiceById(invoiceId) {
        try {
            const response = await axiosInstance.get(`/invoices/${invoiceId}`);
            return response.data;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }

    static async deleteInvoice(invoiceId) {
        try {
            const response = await axiosInstance.delete(
                `/invoices/delete/${invoiceId}`,
            );
            return response;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }
    static async editInvoice(invoiceId, invoice) {
        try {
            const response = await axiosInstance.put(
                `/invoices/edit/${invoiceId}`,
                invoice,
            );
            return response;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }

    static async fetchInvoiceDetails(invoiceId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/invoices/${invoiceId}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching invoice details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async fetchInvoiceDetailsForProject(
        projectId,
        maxRetries,
        retryDelay,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/invoices/project/${projectId}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching invoice details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async createInvoice(invoice, companyId) {
        try {
            const response = await axiosInstance.post(
                `/invoices/new/company/${companyId}`,
                invoice,
            );
            return response;
        } catch (error) {
            console.error("Error creating invoice:", error);
        }
    }
    static async getNextInvoice(companyId) {
        try {
            const response = await axiosInstance.get(
                `/invoices/next-invoice-number/${companyId}`,
            );

            return response.data;
        } catch (error) {
            console.error("Error creating invoice:", error);
        }
    }
}

export default InvoicesService;
