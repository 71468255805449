import { format, getTime, formatDistanceToNow } from "date-fns";
// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
    const fm = newFormat || "dd MMM yyyy";

    return date ? format(new Date(date), fm) : "";
}

export function fDateTime(date, newFormat) {
    console.log("date", date);
    console.log("newFormat", newFormat);
    const fm = newFormat || "dd MMM yyyy p";
    return date ? format(new Date(date), fm) : "";
}
export function fDateTimeIntl(date, lang = "en", options) {
    return new Intl.DateTimeFormat(
        lang,
        options || {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour12: true,
            hour: "2-digit",
            minute: "2-digit",
        },
    ).format(new Date(date));
}

export function fTimestamp(date) {
    return date ? getTime(new Date(date)) : "";
}

export function fToNow(date) {
    return date
        ? formatDistanceToNow(new Date(date), {
              addSuffix: true,
          })
        : "";
}
