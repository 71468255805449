const AIR = [
    "TRANSFER_AIR_DEPARTURE_AIRLINE",
    "TRANSFER_AIR_DEPARTURE_FLIGHT_NO",
    "TRANSFER_DEPARTURE_DATE",
    "TRANSFER_DEPARTURE_TIME",
    "TRANSFER_DEPARTURE_PICKUP",
];

const RAIL = [
    "TRANSFER_RAIL_DEPARTURE_LINE",
    "TRANSFER_RAIL_DEPARTURE_STATION",
    "TRANSFER_DEPARTURE_DATE",
    "TRANSFER_DEPARTURE_TIME",
    "TRANSFER_DEPARTURE_PICKUP",
];

const SEA = [
    "TRANSFER_PORT_CRUISE_SHIP",
    "TRANSFER_DEPARTURE_DATE",
    "TRANSFER_PORT_DEPARTURE_TIME",
    "TRANSFER_DEPARTURE_PICKUP",
];

const getConstants = (questions) => {
    const extraQuestions = ["PICKUP_POINT"];

    const baseObject={
        AIR:AIR.filter(id=>questions.includes(id)),
        RAIL:RAIL.filter(id=>questions.includes(id)),
        SEA:SEA.filter(id=>questions.includes(id)),
        OTHER:[]
    }

    return baseObject;

}

export const DEPARTURE_QUESTIONS_OPTIONS = getConstants;