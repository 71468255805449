const AIR = [
    "TRANSFER_AIR_ARRIVAL_AIRLINE",
    "TRANSFER_AIR_ARRIVAL_FLIGHT_NO",
    "TRANSFER_ARRIVAL_TIME",
];

const RAIL = [
    "TRANSFER_RAIL_ARRIVAL_LINE",
    "TRANSFER_RAIL_ARRIVAL_STATION",
    "TRANSFER_ARRIVAL_TIME",
    "TRANSFER_ARRIVAL_DROP_OFF",
];

const SEA = ["TRANSFER_PORT_ARRIVAL_TIME", "TRANSFER_PORT_CRUISE_SHIP"];

const validateLogistics = (logistics, values) => {
    return (
        logistics?.travelerPickup?.allowCustomTravelerPickup ||
        logistics?.travelerPickup?.locations?.find((location) =>
            values.includes(location.pickupType),
        )
    );
};

const getConstants = (logistics, questions) => {
    const extraQuestions = ["TRANSFER_ARRIVAL_DROP_OFF", "PICKUP_POINT"];

    const baseObject = {
        AIR: {
            values: AIR.filter((id) => questions.includes(id)),
            filterBy: "",
        },
        RAIL: {
            values: RAIL.filter((id) => questions.includes(id)),
            filterBy: "",
        },
        SEA: {
            values: SEA.filter((id) => questions.includes(id)),
            filterBy: "",
        },
        OTHER: { values: [], filterBy: "" },
    };

    baseObject.AIR = validateLogistics(logistics, "AIRPORT")
        ? {
              values: [...baseObject.AIR.values, ...extraQuestions],
              filterBy: "AIRPORT",
          }
        : baseObject.AIR;
    baseObject.SEA = validateLogistics(logistics, "PORT")
        ? {
              values: [...baseObject.SEA.values, ...extraQuestions],
              filterBy: "PORT",
          }
        : baseObject.SEA;
    baseObject.OTHER = validateLogistics(logistics, [
        "OTHER",
        "HOTEL",
        "LOCATION",
    ])
        ? {
              values: [extraQuestions[1]],
              filterBy: ["OTHER", "HOTEL", "LOCATION"],
          }
        : baseObject.OTHER;

    return baseObject;
};

export const ARRIVAL_QUESTIONS_OPTIONS = getConstants;
