import { axiosInstance } from "src/config/axiosInstance";

class CustomersService {
    static async fetchCustomersList(
        companyId,
        maxRetries = 3,
        retryDelay = 3000,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/customers/company/${companyId}`,
                );
                console.log("response at Customer Service", response);
                return response;
            } catch (error) {
                console.error("An error occurred:", error);
                retries++;
                if (retries >= maxRetries) {
                    return error;
                }
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        return [];
    }

    static async deleteCustomerById(customerId) {
        try {
            const response = await axiosInstance.post(
                `/customers/delete/${customerId}`,
            );
            return response;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }

    static async fetchCustomerDetails(customerId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/customers/${customerId}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching customer details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async fetchProjectsByCustomer(customerId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/projects/customer/${customerId}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async fetchCustomersSummary(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `${process.env.REACT_APP_HOST_API}/customers/summary/${companyId}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching customer details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async fetchCustomerBookings(email, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/bookings/customer/allbookings/${email}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching bookings customer:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async fetchCustomerQuotations(customerId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/customers/customer/quotations/${customerId}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching customer quotations:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async fetchCustomerPreferences(email, maxRetries, retryDelay) {
        let retries = 0;
        console.log("email in service", email);
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/customers/preferences/update/${email}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching customer details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    /*  static async fetchCustomerPreferences(
        customerEmail,
        maxRetries = 3,
        retryDelay = 1000,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/customers/preferences/update/:customerEmail${customerEmail}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching customer details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }
*/
    static async updateCustomerPreferences(data) {
        try {
            const response = await axiosInstance.post(
                `/customers/customer/preferences`,
                data,
            );
            console.log("response", response);
            return response;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }

    static async fetchCustomerList(query,limit){

        try{

            const response=await axiosInstance.get(`/customers?name=${query}&limit=${limit}`);

            return response.data;

        }catch(error){
            console.error("An error occurred:", error);
        }

    }
}

export default CustomersService;
