import axiosInstance from "src/config/axiosInstance";

export const getNearestAirports = async (body, maxRetries, retryDelay) => {
    let retries = 0;
    while (retries < maxRetries) {
        try {
            const response = await axiosInstance.post(
                "/flights/nearestAirports",
                body,
                {
                    timeout: 5000,
                },
            );

            return response.data;
        } catch (error) {
            retries++;
            await new Promise((resolve) => setTimeout(resolve, retryDelay));
            if (retries === maxRetries) {
                return [];
            }
        }
    }
};
