import { axiosInstance } from "src/config/axiosInstance";

class TransfersService {
    static async fetchTransferOffers(searchCriteria, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    `/transfers/offers`,
                    searchCriteria,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    },
                );

                return response.data.transfers; // Assuming the flights data is in the response's data property
            } catch (error) {
                console.error("Error creating flight:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        return { data: [] };
    }

    static async deleteTransferById(bookingId, gdsprovider) {
        const body = {
            gdsprovider:gdsprovider.toLowerCase(),
        };

        try {
            const response = await axiosInstance.post(
                `/transfers/cancel/${bookingId}`,
                body,
            );
            return response;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }

    static async fetchTransferDetailsForProject(
        projectId,
        maxRetries,
        retryDelay,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/flights/project/${projectId}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching flight details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async bookTransfer(data, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    `/transfers/book`,
                    data,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    },
                );

                return response.data; // Assuming the project data is in the response's data property
            } catch (error) {
                console.error("Error creating flight:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }
}

export default TransfersService;
