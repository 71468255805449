import { axiosInstance } from "src/config/axiosInstance";

class NotificationsService {
    static async fetchNotificationsList(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/notifications/company/${companyId}`,
                );

                return response;
            } catch (error) {
                console.error("An error occurred:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        return [];
    }
    static async updateNotification(notificationId, updateData) {
        try {
            const response = await axiosInstance.put(
                `/notifications/update/${notificationId}`,
                updateData,
            );
            return response.data;
        } catch (error) {
            console.error(
                "An error occurred while updating the notification:",
                error,
            );
            throw error;
        }
    }
}

export default NotificationsService;
