export const posibleValues = [
    "pickup included",
    "private tour",
    "recogida incluida",
    "tour privado",
    "รวมการรับสินค้า",
    "ทัวร์ส่วนตัว",
    "including pickup",
    "includes transportation",
    "incluida la recogida",
    "incluye transporte",
    "incluida recogida"
];

