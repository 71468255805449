import { useAuthContext } from "src/auth/hooks";

export function useMockedUser() {
    const { user } = useAuthContext();

    const mockedUser = {
        id: user ? user._id : null,
        displayName: user ? user.name : null,
        email: user ? user.email : null,
        password: user ? user.password : null,
        photoURL: user ? user.photoURL : null,
        phone: user ? user.phone : null,
        country: user ? user.country : null,
        address: user ? user.address : null,
        state: user ? user.state : null,
        city: user ? user.city : null,
        zipCode: user ? user.zipCode : null,
        about: user ? user.about : null,
        role: user ? user.role : null,
        isPublic: user ? user.isPublic : null,
    };

    return { user: mockedUser };
}
