import { axiosInstance } from "src/config/axiosInstance";

class ToursService {
    static async fetchToursSummary(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `${process.env.REACT_APP_HOST_API}/projects/itineraries/company/${companyId}`,
                );

                return response;
            } catch (error) {
                console.error("Error fetching tours summary:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }
}

export default ToursService;
