import { axiosInstance } from "src/config/axiosInstance";

class OurproductsService {
    static async fetchOurproductsSummary(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `${process.env.REACT_APP_HOST_API}/ourproducts/${companyId}`,
                );
                console.log(response);
                return response;
            } catch (error) {
                console.error("Error fetching ourproducts summary:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }
}

export default OurproductsService;
