import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import PropTypes from "prop-types";
import { RouterLink } from "src/routes/components";
import { forwardRef } from "react";

const Favicon = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
    const storedUser = sessionStorage.getItem("user");

    let companyfavicon = "/assets/favicon-tz.png";
    let faviconWidth = 180;
    let faviconHeight = 38;

    if (storedUser) {
        const { company: userCompany } = JSON.parse(storedUser);
        faviconWidth = userCompany?.faviconWidth;
        faviconHeight = userCompany?.faviconHeight;
    }

    const favicon = (
        <Box
            ref={ref}
            component="img"
            src={companyfavicon}
            alt="Favicon"
            sx={{
                width: parseInt(faviconWidth),
                height: parseInt(faviconHeight),
                display: "inline-flex",
                cursor: "pointer",
                ...sx,
            }}
            {...other}
        />
    );

    if (disabledLink) {
        return favicon;
    }

    return (
        <Link component={RouterLink} href="/" sx={{ display: "contents" }}>
            {favicon}
        </Link>
    );
});

Favicon.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.object,
};

export default Favicon;
