// assets
import { countries } from "src/assets/data";
//
import { _mock } from "./_mock";

// ----------------------------------------------------------------------

export const PROVIDER_STATUS_OPTIONS = [
    { value: "preferred", label: "Preferred" },
    { value: "backup", label: "Backup" },
];
