import { axiosInstance } from "src/config/axiosInstance";

class WalletService {
    static async fetchWalletDetails(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/wallet/balance/${companyId}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching wallet details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }
}

export default WalletService;
