import { createContext, useContext } from "react";

// ----------------------------------------------------------------------
export const TransferContext = createContext({});

export const useTransferContext = () => {
    const context = useContext(TransferContext);

    if (!context)
        throw new Error(
            "useCheckoutContext must be use inside CheckoutProvider",
        );

    return context;
};
