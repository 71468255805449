import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useLocales } from "src/locales";

// ----------------------------------------------------------------------

export default function SearchNotFound({ query, sx, ...other }) {
    const { t } = useLocales();

    return query ? (
        <Paper
            sx={{
                bgcolor: "unset",
                textAlign: "center",
                ...sx,
            }}
            {...other}
        >
            <Typography variant="h6" gutterBottom>
                {t("Not Found")}
            </Typography>

            <Typography variant="body2">
                {t("No results found for")}&nbsp;
                <strong>&quot;{query}&quot;</strong>.
                <br />
                {t("Try checking for typos or using complete words")}
            </Typography>
        </Paper>
    ) : (
        <Typography variant="body2" sx={sx}>
            {t("Please enter keywords")}
        </Typography>
    );
}

SearchNotFound.propTypes = {
    query: PropTypes.string,
    sx: PropTypes.object,
};
