export const currencies = [
    { value: "EUR", label: "EUR" }, // Euro
    { value: "USD", label: "USD" }, // Dolar estadounidense
    { value: "INR", label: "INR" }, // Rupia India
    { value: "CHF", label: "CHF" }, // Franco Suizo
    { value: "JPY", label: "JPY" }, // Yen Japonés
    { value: "GBP", label: "GBP" }, // Libra Esterlina
    { value: "AUD", label: "AUD" }, // Dolar Australiano
    { value: "CAD", label: "CAD" }, // Dolar Canadiense
    { value: "CNY", label: "CNY" }, // Renminbi Chino
    { value: "SGD", label: "SGD" }, // Dolar Singapurense
    { value: "NZD", label: "NZD" }, // Dolar Neozelandes

    // { value: "HNL", label: "HNL"}, // Lempiras Hondureñas
    { value: "MXN", label: "MXN"}, // Peso Mexicano
    // { value: "CLP", label: "CLP"}, // Peso Chileno
    // { value: "COP", label: "COP"}, // Peso Colombiano
    // { value: "GTQ", label: "GTQ"}, // Queztal Guatemalteco
    // { value: "CRC", label: "CRC"}, // Colon Costarricense
];
