/**
 * Obtains the company ID and user ID from sessionStorage.
 * @returns {Object} An object containing both companyId and userId.
 */
export const getSessionIds = () => {
    const USER_ID = "userId";
    const COMPANY_ID = "companyId";
    const companyId = sessionStorage.getItem(COMPANY_ID);
    const userId = sessionStorage.getItem(USER_ID);

    return { companyId, userId };
};
