import {
    HOST_API,
    HOST_API_CRUISES,
    HOST_API_FLIGHTS,
} from "src/config-global";
import axios, { CanceledError } from "axios";

import qs from "qs";
import { storageKeys } from "src/utils";
import { enqueueSnackbar } from "notistack";

//timeout up to 3 minutes for hotelbeds and TBO requirements
const axiosInstance = axios.create({
    baseURL: HOST_API,
    timeout: 180000,
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = sessionStorage.getItem(storageKeys.TOKEN);
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        const superAdminToken = sessionStorage.getItem(
            storageKeys.SUPERADMIN_TOKEN,
        );
        if (superAdminToken) {
            config.headers["Admin-Authorization"] = `Bearer ${superAdminToken}`;
        }
        // let userAgent, os;
        // if (navigator?.userAgentData?.brands) {
        //   userAgent = navigator.userAgentData.brands[0].brand
        // } else {

        // }
        // if (navigator?.userAgentData?.platform) {
        //   os = navigator.userAgentData.platform;
        // } else {

        // }
        // config.headers["custom-user-agent"] = userAgent;
        // config.headers["custom-os"] = os;
        return config;
    },
    (error) => Promise.reject(new Error(error)),
);

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error?.response?.status === 498) {
            appLogout(error.response.data.message || "Session Expired");
            return Promise.resolve("Session Expired");
        }
        if (error instanceof CanceledError) {
            return Promise.reject(error);
        }
        return Promise.reject(error.response?.data || "Something went wrong");
    },
);

const flightAPI = axios.create({
    baseURL: HOST_API_FLIGHTS,
});

flightAPI.interceptors.response.use(
    (res) => res,
    (err) =>
        Promise.reject(
            (err.response && err.response.data) || "Something went wrong",
        ),
);

const cruiseAPI = axios.create({
    baseURL: HOST_API_CRUISES,
    paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "brackets" }),
});

cruiseAPI.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem(storageKeys.TOKEN);
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error),
);

cruiseAPI.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error instanceof CanceledError) {
            return Promise.reject(error);
        }
        return Promise.reject(
            (error.response && error.response.data) || "Something went wrong",
        );
    },
);

function appLogout(message) {
    enqueueSnackbar(message, { variant: "error" });
    localStorage.removeItem(storageKeys.TOKEN);
    localStorage.removeItem(storageKeys.SUPERADMIN_TOKEN);
    sessionStorage.clear();
    delete axiosInstance.defaults.headers.common.Authorization;
    window.location.replace("/");
}

export { axiosInstance, flightAPI, cruiseAPI };
export default axiosInstance;
