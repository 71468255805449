import {
    AccountPopover,
    CurrencyPopover,
    LanguagePopover,
    NotificationsPopover,
} from "../_common";
import { HEADER, NAV } from "../config-layout";
import { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Logo from "src/components/logo";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import SvgColor from "src/components/svg-color";
import Toolbar from "@mui/material/Toolbar";
import { bgBlur } from "src/theme/css";
import { useOffSetTop } from "src/hooks/use-off-set-top";
import { useResponsive } from "src/hooks/use-responsive";
import { useSettingsContext } from "src/components/settings";
import { useTheme } from "@mui/material/styles";
import { paths } from "src/routes/paths";
import { useRouter } from "src/routes/hooks";
import { useLocales } from "src/locales";
import { useLocation } from "react-router-dom";

export default function Header({ onOpenNav }) {
    const theme = useTheme();
    const settings = useSettingsContext();
    const isNavHorizontal = settings.themeLayout === "horizontal";
    const isNavMini = settings.themeLayout === "mini";
    const lgUp = useResponsive("up", "lg");
    const offset = useOffSetTop(HEADER.H_DESKTOP);
    const router = useRouter();
    const { t } = useLocales();
    const location = useLocation();
    const offsetTop = offset && !isNavHorizontal;
    const onboardingStatus = sessionStorage.getItem("onboardingStatus");


    const currentPath = location.pathname;

    const [buttonText, setButtonText] = useState(
        "Happy Onboarding, Enter Here!",
    );

    useEffect(() => {
        const timer = setTimeout(() => {
            setButtonText(
                offsetTop ? "Start Here!" : "Happy Onboarding, Enter Here!",
            );
        }, 150);
        return () => clearTimeout(timer);
    }, [offsetTop]);

    const handleOnboarding = async () => {
        try {
            router.push(paths.dashboard.user.account);
        } catch (error) {}
    };

    const renderContent = (
        <>
            {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

            {!lgUp && (
                <IconButton onClick={onOpenNav}>
                    <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
                </IconButton>
            )}

            <Stack
                flexGrow={1}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={{ xs: 0.5, sm: 1 }}
            >
                {/*<CurrencyPopover onChangeCurrency={handleChangeCurrency} />*/}
                {onboardingStatus < 3 &&
                    currentPath !== "/dashboard/user/account" && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOnboarding}
                            sx={{
                                display: { xs: "none", sm: "block" },
                                width: offsetTop ? 200 : 380,
                                height: offsetTop ? 45 : 60,
                                fontSize: offsetTop ? 18 : 22,
                                fontWeight: "bold",
                                borderRadius: offsetTop ? 3 : 2,
                                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.12)",
                                transition: "all 0.3s ease",
                                margin: offsetTop ? 3 : 0,
                                opacity: 0.9,
                                transition:
                                    "opacity 0.3s ease, width 0.4s ease, height 0.4s ease",
                                "&:hover": {
                                    backgroundColor: "secondary.main",
                                    transform: "translateY(-3px)",
                                },
                            }}
                        >
                            {t(buttonText)}
                        </Button>
                    )}
                <LanguagePopover />
                <NotificationsPopover />
                <AccountPopover />
            </Stack>
        </>
    );

    return (
        <AppBar
            sx={{
                height: HEADER.H_MOBILE,
                zIndex: theme.zIndex.appBar + 1,
                ...bgBlur({
                    color: theme.palette.background.default,
                }),
                transition: theme.transitions.create(["height"], {
                    duration: theme.transitions.duration.shorter,
                }),
                ...(lgUp && {
                    width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
                    height: HEADER.H_DESKTOP,
                    ...(offsetTop && {
                        height: HEADER.H_DESKTOP_OFFSET,
                    }),
                    ...(isNavHorizontal && {
                        width: 1,
                        bgcolor: "background.default",
                        height: HEADER.H_DESKTOP_OFFSET,
                        borderBottom: `dashed 1px ${theme.palette.divider}`,
                    }),
                    ...(isNavMini && {
                        width: `calc(100% - ${NAV.W_MINI + 1}px)`,
                    }),
                }),
            }}
        >
            <Toolbar
                sx={{
                    height: 1,
                    px: { lg: 5 },
                    backgroundColor: "#1a1a33",
                }}
            >
                {renderContent}
            </Toolbar>
        </AppBar>
    );
}

Header.propTypes = {
    onOpenNav: PropTypes.func,
};
