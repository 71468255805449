export const languages = [
    {
        label: "English",
        value: "en",
        icon: "flagpack:gb-nir",
    },
    {
        label: "French",
        value: "fr",
        icon: "flagpack:fr",
    },
    {
        label: "Spanish",
        value: "es",
        icon: "flagpack:es",
    },
];
