import PropTypes from "prop-types";
// @mui
import Box from "@mui/material/Box";
// hooks
import { useBoolean } from "src/hooks/use-boolean";
import { useResponsive } from "src/hooks/use-responsive";
// components
import { useSettingsContext } from "src/components/settings";
//
import Main from "./main";
import Header from "./header";
import NavMini from "./nav-mini";
import NavVertical from "./nav-vertical";
import NavHorizontal from "./nav-horizontal";

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
    const settings = useSettingsContext();

    const lgUp = useResponsive("up", "lg");

    const nav = useBoolean();

    const isHorizontal = settings.themeLayout === "horizontal";

    const isMini = settings.themeLayout === "mini";

    const renderNavMini = <NavMini />;

    const renderHorizontal = <NavHorizontal />;

    const renderNavVertical = (
        <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />
    );

    if (isHorizontal) {
        return (
            <>
                <Header onOpenNav={nav.onTrue} />

                {lgUp ? renderHorizontal : renderNavVertical}

                <Main>{children}</Main>
            </>
        );
    }

    if (isMini) {
        return (
            <>
                <Header onOpenNav={nav.onTrue} />

                <Box
                    sx={{
                        minHeight: 1,
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                    }}
                >
                    {lgUp ? renderNavMini : renderNavVertical}

                    <Main
                        sx={{
                            background: `linear-gradient(#1a1a33 340px, transparent 340px)`,
                        }}
                    >
                        {children}
                    </Main>
                </Box>
            </>
        );
    }

    return (
        <>
            <Header onOpenNav={nav.onTrue} />

            <Box
                sx={{
                    minHeight: 1,
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                }}
            >
                {renderNavVertical}

                <Main
                    sx={{
                        background: `linear-gradient(#1a1a33 340px, transparent 340px)`,
                    }}
                >
                    {children}
                </Main>
            </Box>
        </>
    );
}

DashboardLayout.propTypes = {
    children: PropTypes.node,
};
