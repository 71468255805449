import { axiosInstance } from "src/config/axiosInstance";

class LedgerService {
    static async deleteIncomeById(id) {
        try {
            const response = await axiosInstance.post(`/incomes/delete/${id}`);

            return response;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }

    static async deleteExpenseById(id) {
        try {
            const response = await axiosInstance.post(
                `/expensesAccounts/delete/${id}`,
            );

            return response;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }

    static async deleteAccountById(id) {
        try {
            const response = await axiosInstance.post(
                `/chartAccounts/delete/${id}`,
            );

            return response;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }
}

export default LedgerService;
