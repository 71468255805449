import { axiosInstance } from "src/config/axiosInstance";

class LeadsService {
    static async fetchLeadsList(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/leads/company/${companyId}`,
                );

                return response;
            } catch (error) {
                console.error("An error occurred:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        return [];
    }

    static async fetchGroupLeadsList(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/groupLeads/company/${companyId}`,
                );

                return response;
            } catch (error) {
                console.error("An error occurred:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        return [];
    }

    static async deleteLeadById(leadId) {
        try {
            const response = await axiosInstance.post(
                `/leads/delete/${leadId}`,
            );

            return response;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }

    static async fetchLeadDetails(leadId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `${process.env.REACT_APP_HOST_API}/leads/${leadId}`,
                );

                return response;
            } catch (error) {
                console.error("Error fetching lead details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }
}

export default LeadsService;
