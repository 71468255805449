import { Suspense, lazy } from "react";

import AuthClassicLayout from "src/layouts/auth/classic";
import { GuestGuard } from "src/auth/guard";
import { Outlet } from "react-router-dom";
import { SplashScreen } from "src/components/loading-screen";

const JwtLoginPage = lazy(() => import("src/pages/auth/jwt/login"));
const JwtRegisterPage = lazy(() => import("src/pages/auth/jwt/register"));

const authJwt = {
    path: "jwt",
    element: (
        <GuestGuard>
            <Suspense fallback={<SplashScreen />}>
                <Outlet />
            </Suspense>
        </GuestGuard>
    ),
    children: [
        {
            path: "login",
            element: (
                <AuthClassicLayout>
                    <JwtLoginPage />
                </AuthClassicLayout>
            ),
        },
        {
            path: "register",
            element: (
                <AuthClassicLayout title="Let Artificial Intelligence do the job for you">
                    <JwtRegisterPage />
                </AuthClassicLayout>
            ),
        },
    ],
};

export const authRoutes = [
    {
        path: "auth",
        children: [authJwt],
    },
];
