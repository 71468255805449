import { createContext, useContext } from "react";

// ----------------------------------------------------------------------
export const FlightContext = createContext({});

export const useFlightContext = () => {
    const context = useContext(FlightContext);

    if (!context)
        throw new Error(
            "useCheckoutContext must be use inside CheckoutProvider",
        );

    return context;
};
