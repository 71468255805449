const sortPriceValuesActivities=[
    {
        value: 'default',
        label: 'Default'
    },
    {
        value: 'high',
        label: 'Highest'
    },
    {
        value: 'low',
        label: 'Lowest'
    }
]

const sortDurationValuesActivities=[
    {
        value: 'default',
        label: 'Default'
    },
    {
        value: 'high',
        label: 'Longest'
    },
    {
        value: 'low',
        label: 'Shortest'
    }
]

export {
    sortPriceValuesActivities,
    sortDurationValuesActivities
};