export const defaultObjectValues = {
    FULL_NAMES_FIRST: "",
    FULL_NAMES_LAST: "",
    PASSPORT_NATIONALITY: {},
    PASSPORT_EXPIRY: null,
    DATE_OF_BIRTH: null,
    PASSPORT_PASSPORT_NO: "",
    HEIGHT: "",
    WEIGHT: "",
};

export const evitFields = ["AGEBAND", "PASSPORT_NATIONALITY"];
