export const htmlToPlainText = (html) => {
    if (!html) {
        return "";
    }

    return html
        .replace(/<br\s*\/?>/gi, "\n")
        .replace(/<p><\/p>/gi, "\n")
        .replace(/<p>/gi, "")
        .replace(/<\/p>/gi, "\n")
        .replace(/<em>/gi, "")
        .replace(/<\/em>/gi, "")
        .replace(/<a[^>]*>/gi, "")
        .replace(/<\/a>/gi, "");
};

export const parseHTMLTextToString = (str) => {
  if ((str===null) || (str===''))
      return false;
  else
      str = str.toString();
        
  // Regular expression to identify HTML tags in 
  // the input string. Replacing the identified 
  // HTML tag with a null string.
  return str.replace( /(<([^>]+)>)/ig, '');
}