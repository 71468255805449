import { axiosInstance } from "src/config/axiosInstance";

export const fetcher = async (args) => {
    const [url, config] = Array.isArray(args) ? args : [args];

    const res = await axiosInstance.get(url, { ...config });

    return res.data;
};

export const endpoints = {
    chat: "/api/chat",
    kanban: "/tasks/api/kanban",
    calendar: "/api/calendar",
    auth: {
        me: "/users/token",
        login: "/users/login",
        logout: "/users/logout",
        register: "/api/auth/register",
        registerFromLogin: "/users/register",
        exchangeRate: "/users/exchangeRate",
    },
    mail: {
        list: "/emails",
        details: "/api/mail/details",
        labels: "/api/mail/labels",
    },
    post: {
        list: "/posts",
        details: "/posts",
        latest: "/api/post/latest",
        search: "/api/post/search",
    },
    product: {
        list: "/api/product/list",
        details: "/api/product/details",
        search: "/api/product/search",
    },
    providers: {
        list: "/providers",
        details: "/providers/:id",
        search: "/api/product/search",
    },
    leads: {
        list: "/leads",
        details: "/leads/:id",
        search: "/api/product/search",
    },
    users: {
        adminChangePassword: (userId) =>
            "/users/admin/changePassword/" + userId,
        adminLogin: (userId) => "/users/admin/login/" + userId,
    },
};
