const sameTextFieldValues = [
    "PASSPORT_NATIONALITY",
    "PASSPORT_PASSPORT_NO",
    "FULL_NAMES_FIRST",
    "FULL_NAMES_LAST",
    "TRANSFER_AIR_ARRIVAL_AIRLINE",
    "TRANSFER_AIR_ARRIVAL_FLIGHT_NO",
    "TRANSFER_AIR_DEPARTURE_AIRLINE",
    "TRANSFER_AIR_DEPARTURE_FLIGHT_NO",
    "TRANSFER_DEPARTURE_PICKUP",
    "TRANSFER_PORT_CRUISE_SHIP",
    "TRANSFER_RAIL_ARRIVAL_LINE",
    "TRANSFER_RAIL_ARRIVAL_STATION",
    "TRANSFER_RAIL_DEPARTURE_LINE",
    "TRANSFER_RAIL_DEPARTURE_STATION",
    "TRANSFER_ARRIVAL_DROP_OFF"
];
const sameDateFiledValues = ["DATE_OF_BIRTH", "PASSPORT_EXPIRY","TRANSFER_DEPARTURE_DATE"];
const sameSelectOptions = ["AGEBAND","TRANSFER_DEPARTURE_MODE","TRANSFER_ARRIVAL_MODE"];
const sameCountryValues = ["PASSPORT_NATIONALITY"];
const sameTimeValues=["TRANSFER_ARRIVAL_TIME","TRANSFER_DEPARTURE_TIME","TRANSFER_PORT_ARRIVAL_TIME","TRANSFER_PORT_DEPARTURE_TIME"];


export {
    sameTextFieldValues,
    sameDateFiledValues,
    sameSelectOptions,
    sameCountryValues,
    sameTimeValues
}