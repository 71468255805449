// assets
//

// ----------------------------------------------------------------------

export const TASK_STATUS_OPTIONS = [
    { value: "pending", label: "Pending" },
    { value: "waiting", label: "Waiting For Customer" },
    { value: "solved", label: "Solved" },
    { value: "cancelled", label: "Cancelled" },
];

export const PRIORITY_STATUS_OPTIONS = [
    { value: "low", label: "Low" },
    { value: "medium", label: "Medium" },
    { value: "high", label: "High" },
];
