import { t } from "i18next";

export const POST_PUBLISH_OPTIONS = [
    {
        value: "published",
        label: "Published",
    },
    {
        value: "draft",
        label: "Draft",
    },
];

export const POST_SORT_OPTIONS = [
    { value: "latest", label: t("Latest") },
    { value: "popular", label: t("Popular") },
    { value: "oldest", label: t("Oldest") },
];
