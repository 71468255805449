export const checkBoxitem = [
    {
        id: 1,
        label: "Best Quality Price Hotels",
        checked: false,
        value: "hotel",
    },
    {
        id: 2,
        label: "Restaurants",
        checked: false,
        value: "restaurants",
    },
    {
        id: 3,
        label: "Transfers",
        checked: false,
        value: "transfers",
    },
    {
        id: 4,
        label: "Flights",
        checked: false,
        value: "flights",
    },
    {
        id: 5,
        label: "Tips for Must-See Attractions",
        checked: false,
        value: "attractions",
    },
    /* {
        id: 6,
        label: "Good Food",
        checked: false,
        value: "goodFood",
    },*/
    // {
    //     id: 7,
    //     label: "Art & Museums",
    //     checked: false,
    //     value: "artMuseums",
    // },
    // {
    //     id: 8,
    //     label: "Spa & relax",
    //     checked: false,
    //     value: "spaRelax",
    // },
];
