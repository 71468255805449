let worker = null;
let port = null;

try {
    worker = new SharedWorker(
        new URL("./auth.worker.js", import.meta.url),
        { type: "module" },
    );
    port = worker.port;
    port.start();
} catch (error) {
    console.error("SharedWorker no soportado:", error);
}

export const getWorkerPort = () => port; 