import { paths } from "src/routes/paths";
import { useRouter } from "src/routes/hooks";
import { useLocales } from "src/locales";

const SuscriptionSlider = () => {
    const { t } = useLocales();
    const router = useRouter();

    const handleGetSubscription = () => {
        // Redirect the user to the billing page
        console.log("Redirecting to billing page...");
        router.push(paths.dashboard.user.account);
    };

    return (
        <div className="sliding-message show">
            <p>
                {t(
                    "Get the full potential and avoid interruptions by updating your subscriptionPlan",
                )}
            </p>
            <button
                variant="outlined"
                color="inherit"
                onClick={handleGetSubscription}
            >
                {t("Update subscriptionPlan")}
            </button>
        </div>
    );
};

export default SuscriptionSlider;
