const unitsObject = ["HEIGHT", "WEIGHT"];
const stringsObject = [
    "AGEBAND",
    "FULL_NAMES_FIRST",
    "FULL_NAMES_LAST",
    "PASSPORT_NATIONALITY",
    "PASSPORT_EXPIRY",
    "DATE_OF_BIRTH",
    "PASSPORT_PASSPORT_NO",
    "TRANSFER_AIR_ARRIVAL_AIRLINE",
    "TRANSFER_AIR_ARRIVAL_FLIGHT_NO",
    "TRANSFER_AIR_DEPARTURE_AIRLINE",
    "TRANSFER_AIR_DEPARTURE_FLIGHT_NO",
    "TRANSFER_ARRIVAL_TIME",
    "TRANSFER_DEPARTURE_DATE",
    "TRANSFER_DEPARTURE_TIME",
    "TRANSFER_PORT_ARRIVAL_TIME",
    "TRANSFER_PORT_CRUISE_SHIP",
    "TRANSFER_PORT_DEPARTURE_TIME",
    "TRANSFER_RAIL_ARRIVAL_LINE",
    "TRANSFER_RAIL_ARRIVAL_STATION",
    "TRANSFER_RAIL_DEPARTURE_LINE",
    "TRANSFER_RAIL_DEPARTURE_STATION",
    "SPECIAL_REQUIREMENTS",
    "TRANSFER_ARRIVAL_MODE",
    "TRANSFER_DEPARTURE_MODE"
];
const locationObject=["PICKUP_POINT"]

const locationFreeTextObject = ["TRANSFER_ARRIVAL_DROP_OFF","TRANSFER_DEPARTURE_PICKUP","PICKUP_FREETEXT"];

export {
    unitsObject,
    stringsObject,
    locationObject,
    locationFreeTextObject
}