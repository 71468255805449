import { paths } from "src/routes/paths";

export const HOST_API = process.env.REACT_APP_HOST_API;
export const HOST_API_FLIGHTS = process.env.REACT_APP_HOST_API_FLIGHTS;
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;
// export const PREMIUM_PRICE_ID = process.env.REACT_APP_PREMIUM_PRICE_ID;
// export const STARTER_PRICE_ID = process.env.REACT_APP_STARTER_PRICE_ID;
// export const BASIC_PRICE_ID = process.env.REACT_APP_BASIC_PRICE_ID;
export const MUI_PRO_LICENSE_KEY = process.env.REACT_APP_MUI_PRO_LICENSE_KEY;

export const HOST_API_CRUISES = process.env.HOST_API_CRUISES;

export const FIREBASE_API = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const AMPLIFY_API = {
    userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
    userPoolWebClientId:
        process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
    region: process.env.REACT_APP_AWS_AMPLIFY_REGION,
};

export const AUTH0_API = {
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    callbackUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'

export const FLIGHT_API_KEY = process.env.REACT_APP_FLIGHT_KEY;
