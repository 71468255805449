import PropTypes from "prop-types";
import { useState } from "react";
// @mui
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
// utils
import { fToNow } from "src/utils/format-time";
// components
import Label from "src/components/label";
import FileThumbnail from "src/components/file-thumbnail";
import DialogComponent from "./dialogComponent";
import { NotificationsService, ProjectsService } from "src/api/tz";
import { paths } from "src/routes/paths";
import { useRouter } from "src/routes/hooks";
// ----------------------------------------------------------------------

export default function NotificationItem({ notification }) {
    const [isDialogOpen, setDialogOpen] = useState(false);
    const { updateNotification } = NotificationsService;
    const router = useRouter();

    const handleDialogOpen = () => {
        setDialogOpen(true);
        updateNotification(notification._id, { isUnRead: false });
    };

    const renderAvatar = (
        <ListItemAvatar>
            {notification.avatarUrl ? (
                <Avatar
                    src={notification.avatarUrl}
                    sx={{ bgcolor: "background.neutral" }}
                />
            ) : (
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        width: 40,
                        height: 40,
                        borderRadius: "50%",
                        bgcolor: "background.neutral",
                    }}
                >
                    <Box
                        component="img"
                        src={`/assets/icons/notification/${
                            (notification.type === "order" && "ic_order") ||
                            (notification.type === "chat" && "ic_chat") ||
                            (notification.type === "email" && "ic_mail") ||
                            (notification.type === "delivery" && "ic_delivery") || 
                            (notification.type === "customer" && "ic_customer") || 
                            "ic_notification"
                        }.svg`}
                        sx={{ width: 24, height: 24 }}
                    />
                </Stack>
            )}
        </ListItemAvatar>
    );

    const renderText = (
        <ListItemText
            disableTypography
            primary={reader(notification.title)}
            secondary={
                <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ typography: "caption", color: "text.disabled" }}
                    divider={
                        <Box
                            sx={{
                                width: 2,
                                height: 2,
                                bgcolor: "currentColor",
                                mx: 0.5,
                                borderRadius: "50%",
                            }}
                        />
                    }
                >
                    {fToNow(notification.createdAt)}
                    {notification.category}
                </Stack>
            }
        />
    );

    const projectAction = (
        <Stack alignItems="flex-start">
            <Box
                sx={{
                    p: 1.5,
                    my: 1.5,
                    borderRadius: 1.5,
                    color: "text.secondary",
                    bgcolor: "background.neutral",
                }}
            >
                {reader(
                    `${notification.from} replied to ${notification.subject}`,
                )}
            </Box>
        </Stack>
    );

    const renderUnReadBadge = notification.isUnRead && (
        <Box
            sx={{
                top: 26,
                width: 8,
                height: 8,
                right: 20,
                borderRadius: "50%",
                bgcolor: "info.main",
                position: "absolute",
            }}
        />
    );

    return (
        <>
            <ListItemButton
                disableRipple
                onClick={handleDialogOpen} // Open the dialog when the notification is clicked
                sx={{
                    p: 2.5,
                    alignItems: "flex-start",
                    borderBottom: (theme) =>
                        `dashed 1px ${theme.palette.divider}`,
                }}
            >
                {renderUnReadBadge}

                {renderAvatar}
                <Stack sx={{ flexGrow: 1 }}>
                    {renderText}
                    {notification.type === "email" && projectAction}
                </Stack>
            </ListItemButton>
            {isDialogOpen && (
                <DialogComponent
                    open={isDialogOpen}
                    onClose={() => setDialogOpen(false)}
                    notification={notification}
                />
            )}
        </>
    );
}

function reader(data) {
    return (
        <Box
            dangerouslySetInnerHTML={{ __html: data }}
            sx={{
                mb: 0.5,
                "& p": { typography: "body2", m: 0 },
                "& a": { color: "inherit", textDecoration: "none" },
                "& strong": { typography: "subtitle2" },
            }}
        />
    );
}

NotificationItem.propTypes = {
    notification: PropTypes.object,
};

// ----------------------------------------------------------------------
