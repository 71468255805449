import { axiosInstance } from "src/config/axiosInstance";

class AiPilotService {
    static async fetchAiActions(companyId, actionType, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `${process.env.REACT_APP_HOST_API}/aipilot/${actionType}/${companyId}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching campaigns :", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }
    static async createAiActions(data) {
        try {
            const response = await axiosInstance.post(`/aipilot/new`, data);
            return response;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }
    static async updateAiAction(aiActionId, data) {
        data.aiActionId = aiActionId;
        try {
            const response = await axiosInstance.put(
                `/aipilot/update/${aiActionId}`,
                data,
            );
            return response;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }
    static async deleteAiActionById(aiActionId) {
        try {
            const response = await axiosInstance.post(
                `/aipilot/delete/${aiActionId}`,
            );
            return response;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }
}

export default AiPilotService;
