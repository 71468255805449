import { axiosInstance } from "src/config/axiosInstance";

export const uploadToSAAS = async (params) => {
    const formData = new FormData();

    formData.append("fileName", params.fileName);
    formData.append("bucket", params.bucket);
    formData.append("folder", params.folder);
    formData.append(
        "file",
        new Blob([params.file], { type: params.fileType }),
        params.fileName,
    );
    formData.append("fileType", params.fileType);

    try {
        const response = await axiosInstance.post(
            `${process.env.REACT_APP_HOST_API}/uploadToS3`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data", // This is important when dealing with FormData
                },
            },
        );

        return response.data.url; // Assuming the URL is in the 'url' field of the response
    } catch (err) {
        console.error(
            "Upload error: ",
            err.response ? err.response.data : err.message,
        );
        throw err; // Re-throw the error if needed or handle it accordingly
    }
};
